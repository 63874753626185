import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

@Pipe({
    name: 'discount',
    standalone: false
})
export class DiscountPipe implements PipeTransform {
  transform(pvp: number, pvpAnterior: number): number {
    return this.getDescuento(pvp, pvpAnterior);
  }

  @memo({ resolver: getUniqueKey })
  getDescuento(pvp, pvpAnterior) {
    const descuento = 100 - (pvp / pvpAnterior) * 100;
    return Math.round(descuento * 100) / 100;
  }
}

export function getUniqueKey(...args: unknown[]) {
  return args.join('-');
}
